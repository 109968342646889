import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c24ce8ca = () => interopDefault(import('../pages/category/index.vue' /* webpackChunkName: "pages/category/index" */))
const _4c37f1c8 = () => interopDefault(import('../pages/channel/index.vue' /* webpackChunkName: "pages/channel/index" */))
const _5669f944 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _6899ddb0 = () => interopDefault(import('../pages/embed/not-found.vue' /* webpackChunkName: "pages/embed/not-found" */))
const _73aac42a = () => interopDefault(import('../pages/my-channel/bookmarks.vue' /* webpackChunkName: "pages/my-channel/bookmarks" */))
const _bcdb6a84 = () => interopDefault(import('../pages/my-channel/following.vue' /* webpackChunkName: "pages/my-channel/following" */))
const _2bb84c54 = () => interopDefault(import('../pages/search/all.vue' /* webpackChunkName: "pages/search/all" */))
const _1bafbda3 = () => interopDefault(import('../pages/search/chapters.vue' /* webpackChunkName: "pages/search/chapters" */))
const _4d250251 = () => interopDefault(import('../pages/search/curations.vue' /* webpackChunkName: "pages/search/curations" */))
const _1b7b5f75 = () => interopDefault(import('../pages/search/videos.vue' /* webpackChunkName: "pages/search/videos" */))
const _3404e510 = () => interopDefault(import('../pages/channel/_channelSlug/index.vue' /* webpackChunkName: "pages/channel/_channelSlug/index" */))
const _77c9984c = () => interopDefault(import('../pages/embed/_id.vue' /* webpackChunkName: "pages/embed/_id" */))
const _04c2377c = () => interopDefault(import('../pages/video/_id.vue' /* webpackChunkName: "pages/video/_id" */))
const _d526f1fa = () => interopDefault(import('../pages/category/_categorySlug/all.vue' /* webpackChunkName: "pages/category/_categorySlug/all" */))
const _7b65f6c6 = () => interopDefault(import('../pages/channel/_channelSlug/_panelSlug/index.vue' /* webpackChunkName: "pages/channel/_channelSlug/_panelSlug/index" */))
const _6ee4da8a = () => interopDefault(import('../pages/channel/_channelSlug/_panelSlug/_playlistSlug/index.vue' /* webpackChunkName: "pages/channel/_channelSlug/_panelSlug/_playlistSlug/index" */))
const _4b448b28 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/category",
    component: _c24ce8ca,
    name: "category"
  }, {
    path: "/channel",
    component: _4c37f1c8,
    name: "channel"
  }, {
    path: "/privacy",
    component: _5669f944,
    name: "privacy"
  }, {
    path: "/embed/not-found",
    component: _6899ddb0,
    name: "embed-not-found"
  }, {
    path: "/my-channel/bookmarks",
    component: _73aac42a,
    name: "my-channel-bookmarks"
  }, {
    path: "/my-channel/following",
    component: _bcdb6a84,
    name: "my-channel-following"
  }, {
    path: "/search/all",
    component: _2bb84c54,
    name: "search-all"
  }, {
    path: "/search/chapters",
    component: _1bafbda3,
    name: "search-chapters"
  }, {
    path: "/search/curations",
    component: _4d250251,
    name: "search-curations"
  }, {
    path: "/search/videos",
    component: _1b7b5f75,
    name: "search-videos"
  }, {
    path: "/channel/:channelSlug",
    component: _3404e510,
    name: "channel-channelSlug"
  }, {
    path: "/embed/:id?",
    component: _77c9984c,
    name: "embed-id"
  }, {
    path: "/video/:id?",
    component: _04c2377c,
    name: "video-id"
  }, {
    path: "/category/:categorySlug/all",
    component: _d526f1fa,
    name: "category-categorySlug-all"
  }, {
    path: "/channel/:channelSlug/:panelSlug",
    component: _7b65f6c6,
    name: "channel-channelSlug-panelSlug"
  }, {
    path: "/channel/:channelSlug/:panelSlug/:playlistSlug",
    component: _6ee4da8a,
    name: "channel-channelSlug-panelSlug-playlistSlug"
  }, {
    path: "/",
    component: _4b448b28,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
