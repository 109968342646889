import Cookie from 'universal-cookie'
const jwt = require('jsonwebtoken')

export default function ({
  app,
  req
}) {
  const cookies = new Cookie(req && req.headers.cookie)

  return {
    httpEndpoint: app.context.env.hasuraEndpoint,
    wsEndpoint: app.context.env.hasuraEndpoint.replace(/http/, 'ws'),
    tokenName: 'apollo-token',
    websocketsOnly: false,
    apollo: {
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache',
        },
        query: {
          fetchPolicy: 'no-cache',
        },
        mutate: {
          fetchPolicy: 'no-cache',
        },
      },
    },
    getAuth: () => {
      if (app.context.query && app.context.query.token) {
        var decoded = jwt.decode(app.context.query.token)
        return decoded ? 'Bearer ' + app.context.query.token : ''
      } else {
        const token = cookies.get('apollo-token')
        return token ? 'Bearer ' + token : ''
      }
    }
  }
}
