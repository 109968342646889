export const state = () => ({
  pageVideoId: null,
  state: 'UNSTARTED',
  video: null,
  videoId: '',
  currentChapter: null,
  currentCuration: null,
  curationsMenuIsShowed: true,
})


export const getters = {
  isVideoLoaded: state => (state.state == 'PLAYING' ||
    state.state == 'PAUSED' ||
    state.state == 'BUFFERING' ||
    state.state == 'CUED')
}


export const mutations = {
  loadVideo(state, payload) {
    state.videoId = payload.videoId
    state.video = payload.video
  },
  setCurationsMenuIsShowed(state, newState) {
    state.curationsMenuIsShowed = newState
  },
  setPageVideoid(state, newVideoId) {
    state.pageVideoId = newVideoId
  },
  setCurrentChapter(state, chapter) {
    state.currentChapter = chapter
  },
  setCurrentCuration(state, curation) {
    state.currentCuration = curation
  },
  changeState(state, newState) {
    switch (newState) {
      case -1:
        state.state = 'UNSTARTED'
        break
      case 0:
        state.state = 'ENDED'
        break
      case 1:
        state.state = 'PLAYING'
        break
      case 2:
        state.state = 'PAUSED'
        break
      case 3:
        state.state = 'BUFFERING'
        break
      case 5:
        state.state = 'CUED'
        break
      default:
        state.state = 'UNSTARTED'
        break
    }
  }
}
