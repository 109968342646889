export default (
  { graphQLErrors, networkError, operation, forward, extensions, message },
  nuxtContext
) => {
  console.group('%cGlobal error handler:', 'font-weight: 900;')
  console.error(message, extensions)
  console.error(nuxtContext)
  console.error(graphQLErrors, networkError, operation, forward)
  console.groupEnd()
}
