const middleware = {}

middleware['check-token'] = require('../middleware/check-token.js')
middleware['check-token'] = middleware['check-token'].default || middleware['check-token']

middleware['direct-redirect'] = require('../middleware/direct-redirect.js')
middleware['direct-redirect'] = middleware['direct-redirect'].default || middleware['direct-redirect']

middleware['isAuth'] = require('../middleware/isAuth.js')
middleware['isAuth'] = middleware['isAuth'].default || middleware['isAuth']

export default middleware
