import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

import InfiniteLoading from 'vue-infinite-loading'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import Vue from 'vue'
import VueYoutube from 'vue-youtube'

Vue.use(InfiniteLoading)
Vue.use(PerfectScrollbar)
Vue.use(VueYoutube)
