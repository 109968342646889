export const state = () => ({
  user: null,
  sharePopin: {
    enabled: false,
    video: null
  }
})

export const mutations = {
  set_user(state, user) {
    state.user = user
  },
  set_share_popin_video(state, video) {
    state.sharePopin.video = video
  },
  toggle_share_popin(state) {
    state.sharePopin.enabled = !state.sharePopin.enabled
  }
}


export const getters = {
  isConnected: state => state.user != null
}
