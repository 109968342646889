import Vue from 'vue'

Vue.filter('hostname', input => {
  if (input) {
    return new URL(input).hostname
  } else {
    return ""
  }
})

Vue.filter('toHumanTime', function (input) {
  var hours, minutes, sec_num, seconds, time
  sec_num = parseInt(input, 10)
  hours = Math.floor(sec_num / 3600)
  minutes = Math.floor((sec_num - hours * 3600) / 60)
  seconds = sec_num - hours * 3600 - minutes * 60
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }
  time = ''
  if (parseInt(hours) > 0) {
    time = hours + ' h ' + minutes + ' min'
  } else {
    time = minutes + ' min '
  }
  if (isNaN(seconds)) {
    return ''
  }
  return time
})

Vue.filter('toTime', function (input) {
  var hours, minutes, sec_num, seconds, time
  sec_num = parseInt(input, 10)
  hours = Math.floor(sec_num / 3600)
  minutes = Math.floor((sec_num - hours * 3600) / 60)
  seconds = sec_num - hours * 3600 - minutes * 60
  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }
  time = ''
  if (parseInt(hours) > 0) {
  time = hours + ':' + minutes + ':' + seconds
  } else {
  time = minutes + ":" + seconds;
  }
  if (isNaN(seconds)) {
    return ''
  }
  return time
})
