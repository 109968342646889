const jwt = require('jsonwebtoken')

export default async function ({
  app,
  redirect,
  store,
  error
}) {
  const jwt_token = app.$apolloHelpers.getToken()
  if (jwt_token) {
    var decoded = jwt.decode(jwt_token);

    if (decoded && decoded.exp && parseInt(decoded.exp) < parseInt(Date.now() / 1000)) {
      return redirect('/users/auth-jwt/refresh-token?url=' + app.context.req.originalUrl)
    } else if (decoded && decoded.user && !store.state.user) {
      store.commit('set_user', decoded.user)
      const redirect_after_sign_in_path = app.$cookies.get("redirect_after_sign_in_path")
      if (redirect_after_sign_in_path){
        app.$cookies.remove("redirect_after_sign_in_path");
        return redirect(redirect_after_sign_in_path)
      }
    }
  }
}
