export default function ({
  app,
  store,
  redirect
}) {
  if (!store.getters['isConnected']) {
    app.$cookies.set("redirect_after_sign_in_path", app.context.req.originalUrl);
    return redirect(process.env.LOGIN_URL)
  }
}
